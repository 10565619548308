import React from 'react';
import TechnicalPaperCrousal from './TechnicalPaperCrousal';
import { BiLinkExternal } from 'react-icons/bi';
import { BiRightArrowAlt } from 'react-icons/bi';
import { isMobile } from 'react-device-detect';
import BlogOne from '../../static/BlogOne.webp';
import BlogTwo from '../../static/BlogTwo.jpg';
import BlogThree from '../../static/BlogThree.png';
import BlogFour from '../../static/BlogFour.png';
import BlogFive from '../../static/BlogFive.webp';
import BlogSix from '../../static/BlogSix.png';

function MediaSection() {
	return (
		<div className='flex flex-col space-y-10 w-full mt-24 sm:mt-40'>
			<div className='flex flex-col px-4 sm:px-28'>
				<p className='text-base font-semibold text-[#1D2330] w-full border-b border-[#5F6368] pb-3'>
					Media
				</p>
			</div>
			<div className='grid grid-cols-1 sm:grid-cols-3 sm:pl-24 gap-y-10'>
				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogOne}
							alt='BlogOne'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2 overflow-hidden'>
								Two Indians among ten chosen "aquapreneurs" to receive HCL...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6 overflow-hidden'>
								Securing the world's global freshwater ecosystems, under
								increasing threat...
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold cursor-pointer'
								onClick={() =>
									window.open(
										'https://www.businessinsider.in/sustainability/news/two-indians-among-ten-chosen-aquapreneurs-to-receive-hcl-mega-grant-for-water-conservation-efforts/articleshow/97093472.cms'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://www.businessinsider.in/sustainability/news/two-indians-among-ten-chosen-aquapreneurs-to-receive-hcl-mega-grant-for-water-conservation-efforts/articleshow/97093472.cms'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>
				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogFour}
							alt='BlogFour'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2'>
								Five Questions With Snehal Verma, Co-Founder of NatureDots...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6'>
								Tell us about your venture and what inspired you to start it....
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold'
								onClick={() =>
									window.open(
										'https://entrepreneurship.duke.edu/five-questions-with-snehal-verma-co-founder-of-naturedots/'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://entrepreneurship.duke.edu/five-questions-with-snehal-verma-co-founder-of-naturedots/'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>
				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogTwo}
							alt='BlogTwo'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2'>
								10 Entrepreneurs Share CHF1.75 Million To Tackle Global
								Freshwater Crisis...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6'>
								By 2030 the global demand for water will exceed sustainable
								supply by 40%...
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold cursor-pointer'
								onClick={() =>
									window.open(
										'https://www.hcltech.com/press-releases/10-entrepreneurs-share-chf175-million-tackle-global-freshwater-crisis'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://www.hcltech.com/press-releases/10-entrepreneurs-share-chf175-million-tackle-global-freshwater-crisis'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>
				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogThree}
							alt='BlogThree'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2'>
								This is what needs to happen at the UN Water Conference...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6'>
								The UN Water Conference, which kicks off today in New York,
								takes place against ...
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold cursor-pointer'
								onClick={() =>
									window.open(
										'https://www.weforum.org/agenda/2023/03/un-water-conference-what-experts-hope-achieved/'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://www.weforum.org/agenda/2023/03/un-water-conference-what-experts-hope-achieved/'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>

				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogFive}
							alt='BlogFive'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2'>
								Who Are ‘Aquapreneurs’ And How They Help Fight Water
								Insecurity...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6'>
								HCL Group has committed to a $15 million five-year
								investment....
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold'
								onClick={() =>
									window.open(
										'https://www.timesnownews.com/delhi/earth-day-2023-aquapreneurs-and-what-we-know-about-hcl-and-wefs-global-freshwater-challenge-article-99690551'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://www.timesnownews.com/delhi/earth-day-2023-aquapreneurs-and-what-we-know-about-hcl-and-wefs-global-freshwater-challenge-article-99690551'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>
				<div className='px-6 sm:px-3 mx-3 sm:mx-0'>
					<div className='flex flex-col rounded-xl h-full w-full sm:h-[360px] sm:w-[360px] bg-[#FFFFFF] space-y-4'>
						<img
							src={BlogSix}
							alt='BlogSix'
							className='h-[93px] sm:h-[186px] rounded-t-xl'
						/>
						<div className=' flex flex-col px-4 items-start space-y-3'>
							<p className='text-sm sm:text-base font-medium leading-[18px] sm:leading-6 text-[#222222] line-clamp-2'>
								AI-integrated solutions for water-users, asset managers...
							</p>
							<p className='line-clamp-2 text-xs sm:text-sm font-normal text-[#757575] leading-[18px] sm:leading-6'>
								Water scarcity issues have plagued India for long. While
								engineering....
							</p>
							<div
								className='hidden sm:flex items-center text-[#00208C] text-sm font-bold'
								onClick={() =>
									window.open(
										'https://indiawaterreview.in/interview-ai-integrated-solutions-for-water-users-asset-managers-needed/'
									)
								}
							>
								Read Article <BiLinkExternal className='ml-1' />
							</div>
							<div
								className='sm:hidden flex items-center justify-between text-[#00208C] text-sm font-bold w-full'
								onClick={() =>
									window.open(
										'https://indiawaterreview.in/interview-ai-integrated-solutions-for-water-users-asset-managers-needed/'
									)
								}
							>
								<p>Read Article</p>
								<BiRightArrowAlt />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MediaSection;
