import React from 'react';
import { useNavigate } from 'react-router-dom';
import IMG1 from './Assets/IMG1.png';
import IMG2 from './Assets/IMG2.png';
import IMG3 from './Assets/IMG3.jpg';
import IMG4 from './Assets/IMG4.png';
import IMG5 from './Assets/IMG5.png';
import IMG6 from './Assets/IMG6.webp';
import IMG7 from './Assets/IMG7.png';
import IMG8 from './Assets/IMG8.png';
import IMG9 from './Assets/IMG9.jpg';
import IMG10 from './Assets/IMG10.png';
import IMG11 from './Assets/IMG11.jpg';
import IMG12 from './Assets/IMG12.jpg';
import IMG13 from './Assets/IMG13.jpg';
import IMG14 from './Assets/IMG14.png';
import coverImage from './Assets/CoverImage.png';
import AQD from './Assets/AQD.png';
export default function SingleBlogsPage() {
	const navigate = useNavigate();
	return (
		<div className='container mx-auto px-6 sm:px-12 py-8 mt-10 sm:mt-20'>
			<div className='flex flex-col space-y-4'>
				<img
					src={coverImage}
					alt='Fish Ponds and Polluted Water'
					className='w-full h-[360px] rounded-lg object-cover pt-8'
				/>
				<p className='text-sm text-gray-400 pb-2 w-full text-center'>
					Mass death of fishes due to water quality fluctuation and ecological
					factors
				</p>
			</div>
			<div className='text-4xl font-bold mb-4'>
				The Rising Tide: Challenges of Fish Diseases in Global Aquaculture
			</div>
			<p className='text-[#2D3748] text-sm font-bold '>
				Written by Jyoti Matolia | Sept 2024
			</p>
			<p className='py-4 text-sm font-normal'>
				“To cite this article, please use the following format: Matolia, J.
				(2024, September). The Rising Tide: Challenges of Fish Diseases in
				Global Aquaculture. NatureDots.{' '}
				<a
					href='https://naturedots.com/insights/AQN001'
					className='text-blue-500 cursor-pointer underline underline-blue-500'
					onClick={(e) => e.stopPropagation()}
					target='_blank'
					rel='noopener noreferrer'
				>
					https://naturedots.com/insights/AQN001”
				</a>
			</p>
			<section className='mb-8'>
				<h2 className='text-3xl font-semibold mb-2'>Introduction</h2>
				<p className='mb-4'>
					Aquaculture, or fish farming, is a crucial part of our food system,
					providing a significant source of animal protein, food security, and
					livelihoods worldwide. According to the{' '}
					<a
						href='https://openknowledge.fao.org/server/api/core/bitstreams/7493258e-e420-4840-a95d-cfec8833219d/content'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						Food and Agricultural Organization (FAO, 2024)
					</a>
					, global fish production reached 185 million tonnes in 2022, a
					noticeable 4.4% increase from 2020. Fisheries and aquaculture produced
					a record 223.2 million tonnes worth USD 472 billion, providing about
					15% of global animal protein intake, and in some Asian and African
					countries, this figure is over 50%.
				</p>
				<p>
					In freshwater inland fisheries, India is the second-largest producer,
					contributing 13.11 million tonnes of fish, mainly freshwater species
					like carps, which account for 87% of farmed fish, as per the{' '}
					<a
						href='https://dof.gov.in/sites/default/files/2024-03/handbook_sp.pdf'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						Handbook of Fisheries Statistics 2023.
					</a>
				</p>
				<div className='flex flex-col'>
					<div className='flex justify-center h-[200px] sm:h-[500px] my-4 space-x-4 w-full'>
						<img
							src={IMG1}
							alt='Fish Ponds and Polluted Water'
							className='w-full rounded-lg shadow-md object-cover'
						/>
						<img
							src={IMG2}
							alt='Fish Ponds and Polluted Water'
							className='w-full shadow-md rounded-lg object-cover'
						/>
					</div>
					<p className='text-sm text-gray-400 pb-2 w-full text-center'>
						Polluted water bodies impacted by surface runoff and algal blooms,
						threatening aquatic life and water quality in India (Source:
						NatureDots)
					</p>
				</div>
			</section>

			<section className='mb-8'>
				<h2 className='text-3xl font-semibold mb-2'>
					The Shadow of Disease: An Escalating Threat
				</h2>
				<div className='flex flex-col'>
					<div className='flex sm:flex-row flex-col sm:space-x-8'>
						<div className='flex flex-col sm:w-[50%]'>
							<p className='mb-4'>
								Despite observable growth, the aquaculture industry faces a
								major challenge: <b>infectious diseases</b>. As fish farmers
								move to more intensive farming methods, diseases are becoming
								more common, threatening profits and the sustainability of the
								industry. Many farmers use high-density stocking methods that,
								combined with poor practices, climate variability, and
								pollution, create ideal conditions for disease outbreaks. The
								financial impact is immense, with direct losses from fish
								diseases estimated at USD{' '}
								<a
									href='https://digitalarchive.worldfishcenter.org/bitstream/handle/20.500.12348/4833/a4fd37f3aba7ab43f32fabc627508032.pdf?sequence3='
									className='text-blue-500 cursor-pointer underline underline-blue-500'
									onClick={(e) => e.stopPropagation()}
									target='_blank'
									rel='noopener noreferrer'
								>
									6 billion
								</a>{' '}
								each year due to various pathogens-led disease instances.
							</p>
							<p className='mb-4'>
								Especially, If one assess the vulnerability points based on fish
								life stages, we find that the threat is more pronounced at the
								very early stages – ‘Hatcheries’. Hatcheries are largely closed
								and controlled systems where fish seeds are produced. Low
								quality and unhealthy fish seed and fingerlings not only
								increases mortality at various stages of fisheries but also
								causes fish production to be of low quality and more prone to
								other disease in the fisheries cycle. Poorly managed aquaculture
								farms are breeding grounds for viral, bacterial diseases, and
								parasitic disease outbreaks. Overcrowding, combined with
								suboptimal water conditions, creates an environment ripe for
								outbreaks. These diseases can spread rapidly between farms,
								causing significant economic losses and threatening food
								security. We observe that the winter and early spring season,
								often see a spike in disease cases, likely due to combination of
								these factors.
							</p>
						</div>
						<div className='flex items-center justify-center flex-col sm:w-[50%]'>
							<div className='flex justify-center'>
								<img
									src={IMG3}
									alt='dead fish'
									className='rounded-lg shadow-md'
								/>
							</div>
							<p className='text-sm text-gray-400 pb-2 mt-4'>
								Numerous ponds and lakes in India are witnessing massive fish
								mortalities (Source: NatureDots)
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					Nature's Warning: Fish Kill Crisis
				</h3>
				<div className='flex sm:flex-row flex-col sm:space-x-8'>
					<div className='sm:w-[50%]'>
						<p className='mb-4'>
							A scientific paper on the{' '}
							<a
								href='https://www.walshmedicalmedia.com/open-access/incidences-of-infectious-diseases-in-freshwater-aquaculture-farms-of-eastern-india-a-passive-surveillance-based-study-from-2014201-52836.html'
								className='text-blue-500 cursor-pointer underline underline-blue-500'
								onClick={(e) => e.stopPropagation()}
								target='_blank'
								rel='noopener noreferrer'
							>
								incidence of infectious diseases
							</a>{' '}
							reveals alarming statistics about fish diseases. Note that the
							following figures are according to available reported cases,
							whereas most fish disease cases remain unreported:
						</p>
						<ul className='list-disc list-inside mb-4'>
							<li>
								<b>Parasitic Predominance:</b> Parasites account for 74.88% of
								cases.
							</li>
							<li>
								<b>Bacterial Impact:</b> Bacterial infections contribute to
								12.79% of disease incidents.
							</li>
							<li>
								<b>Mixed Threats:</b> A combination of bacteria and parasites is
								responsible for 10.50% of disease outbreaks.
							</li>
							<li>
								<b>Viral Vulnerability:</b> Viral diseases represent 1.83% of
								outbreaks.
							</li>
						</ul>
						<p>
							<a
								href='https://www.walshmedicalmedia.com/open-access/incidences-of-infectious-diseases-in-freshwater-aquaculture-farms-of-eastern-india-a-passive-surveillance-based-study-from-2014201-52836.html'
								className='text-blue-500 cursor-pointer underline underline-blue-500'
								onClick={(e) => e.stopPropagation()}
								target='_blank'
								rel='noopener noreferrer'
							>
								Sahoo et al., 2020
							</a>{' '}
							identified that among the most common parasitic diseases impacting
							freshwater fisheries across the region in South Asia are mixed
							parasitic infections (37.80%), Argulosis (19.51%), and
							Dactylogyrosis (18.90%). The Aeromonas group, which is especially
							harmful to freshwater fish, is responsible for 66.66% of bacterial
							disease cases.
						</p>
					</div>

					<div className='sm:w-[50%]'>
						<img src={IMG5} alt='dead fish' className='w-full h-[400px]' />
					</div>
				</div>

				<div className='flex sm:flex-row flex-col sm:space-x-8 '>
					<div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
						<div className='flex flex-col space-y-3 items-center'>
							<div className='flex justify-center'>
								<img
									src={IMG7}
									alt='dead fish'
									className='w-full h-[350px] rounded-lg shadow-md'
								/>
							</div>
							<p className='text-sm text-gray-400 pb-2'>
								Argulosis parasitic infection (Source:{' '}
								<a
									href='https://ifm.org.uk/wp-content/uploads/2021/08/Argulus-mongolianus-1.pdf'
									className='text-blue-500 cursor-pointer underline underline-blue-500'
									onClick={(e) => e.stopPropagation()}
									target='_blank'
									rel='noopener noreferrer'
								>
									National Fisheries Laboratory, UK)
								</a>
							</p>
						</div>

						<div className='flex flex-col space-y-3 items-center h-full'>
							<div className='flex justify-center h-auto'>
								<img
									src={IMG8}
									alt='dead fish'
									className='w-full h-[350px] rounded-lg shadow-md'
								/>
							</div>
							<p className='text-sm text-gray-400 pb-2'>
								Dactylogyrosis infection (Source:{' '}
								<a
									href='https://www.researchgate.net/publication/354431599_Gross_and_Histopathological_Lesion_Induced_on_the_Gills_Ofbarbus_Fish_by_Monogenea_Trematods_Habiteting_in_Western_Part_of_Lake_Tana_Amhara_Region_Ethiopia'
									className='text-blue-500 cursor-pointer underline underline-blue-500'
									onClick={(e) => e.stopPropagation()}
									target='_blank'
									rel='noopener noreferrer'
								>
									Workeale et al., 2021)
								</a>
							</p>
						</div>
						<div className='flex flex-col'>
							<div className='flex flex-col space-y-3 items-center h-full'>
								<div className='flex justify-center h-full'>
									<img
										src={IMG6}
										alt='dead fish'
										className='w-full  h-[350px] rounded-lg shadow-md'
									/>
								</div>
								<p className=' text-sm text-gray-400 pb-2'>
									Aeromonas infection in Rohu (Source:{' '}
									<a
										href='https://www.mdpi.com/2076-0817/12/4/598?type=check_update&version=2'
										className='text-blue-500 cursor-pointer underline underline-blue-500'
										onClick={(e) => e.stopPropagation()}
										target='_blank'
										rel='noopener noreferrer'
									>
										Behera et al., 2023)
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					The Impact on Major Fish Species Globally
				</h3>
				<p className='mb-4'>
					In India, carp farming is essential, with Indian Major Carps like
					Catla, Rohu, and Mrigal making up 75% of total freshwater fish
					production. However, Catla is particularly vulnerable to diseases due
					to its feeding habits and sensitivity to temperature changes.
				</p>
				<p className='mb-4'>
					Tilapia farming is also facing serious challenges. With 6.5 million
					metric tons of tilapia produced globally in 2022, new viral threats
					such as the Tilapia Lake Virus (TiLV) and the recently identified
					Tilapia Parvovirus (TiPV) have led to significant losses,{' '}
					<a
						href='https://www.sciencedirect.com/science/article/pii/S2468550X21000599?via%3Dihub'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						90% mortality
					</a>{' '}
					causing in some instances. India has reported cases of TiPV, with
					mortality rates of{' '}
					<a
						href='https://www.researchsquare.com/article/rs-3373088/v1'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						30 to 50% in ponds of Tamil Nadu
					</a>
					{', '}{' '}
					<a
						href='https://onlinelibrary.wiley.com/doi/10.1111/jfd.13871'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						59.6% in Maharashtra, and up to 95.0% in Uttar Pradesh.
					</a>
				</p>
				<p>
					Pangasius, a popular fish for farming, is highly susceptible to
					diseases due to its lack of scales, making it more vulnerable to
					infections. Shrimp farming is not spared either; the{' '}
					<a
						href='https://mpeda.gov.in/wp-content/uploads/2020/12/mpeda-annual%20report%202022-2023-2.pdf'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						switch from Black Tiger shrimp (Penaeus monodon) to American White
						Leg shrimp (Litopenaeus vannamei)
					</a>{' '}
					has led to concerns about diseases like White Spot Syndrome Virus
					(WSSV).
				</p>
			</section>

			<section className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					Beyond the Pond: The Human Cost of Fish Disease
				</h3>
				<p className='mb-4'>
					Fish diseases cast a long shadow beyond aquaculture ponds. They’re not
					just an economic setback but a human and food crisis, impacting
					millions reliant on fish for sustenance and livelihood.
				</p>
				<p className='mb-4'>
					<strong>A Village in Peril:</strong> A recent outbreak of Epizootic
					Ulcerative Syndrome (EUS) in Kerala, India's rice bowl districts,
					devastated fish farmers. This highly contagious disease caused severe
					haemorrhages and ulcers, leading to significant fish mortality.{' '}
					<a
						href='https://pubmed.ncbi.nlm.nih.gov/33629661/'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						(Das et al., 2021)
					</a>
				</p>
				<p className='mb-4'>
					A recent study conducted in Indian shrimp farming states estimates
					that infectious shrimp diseases in India cause fish farmers to lose
					around 0.21 M ton shrimp worth US$1.02 annually, particularly due to
					white spot syndrome virus (WSSV), Enterocytozoon hepatorenal (EHP),
					and Vibriosis.
					<a
						href='https://www.sciencedirect.com/science/article/abs/pii/S0044848620339375#:~:text=The%20total%20employment%20loss%20due,shrimp%20worth%20US%24%201.02%20B'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						(Patil et al., 2021)
					</a>
				</p>
				<p>
					<strong>Global Impact:</strong> The World Bank estimates fish diseases
					cost the global aquaculture industry a staggering $6 billion annually.
					This ripples through economies, affecting food prices and livelihoods.{' '}
					<a
						href='https://marinescience.blog.gov.uk/2017/02/02/solving-the-6-billion-per-year-global-aquaculture-disease-problem/'
						className='text-blue-500 cursor-pointer underline underline-blue-500'
						onClick={(e) => e.stopPropagation()}
						target='_blank'
						rel='noopener noreferrer'
					>
						(Stentiford, 2017)
					</a>
				</p>
				<p className='mb-4'>
					<strong>Nutritional Deficiencies:</strong> For millions, freshwater
					fish is a protein powerhouse. In Southeast Asia, where fish
					contributes significantly to dietary protein, disease outbreaks can
					exacerbate malnutrition, especially among children and pregnant women.
				</p>
				<p>
					<strong>Beyond Economics:</strong> Fish diseases disrupts entire
					ecosystems. Reduced fish populations can lead to imbalances, affecting
					biodiversity and livelihoods dependent on fishing and tourism.
				</p>
			</section>

			<section className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					New Frontiers in Fish Disease Detection and Management
				</h3>
				<p className='mb-4'>
					The aquaculture industry is at a crossroads, with disease outbreaks
					posing a significant threat to its sustainability and growth. While
					traditional methods of disease management are essential, emerging
					technologies and innovative approaches are offering new hope. The
					ongoing approach of disease detection is inefficacious and untimely
					detection of disease, rather it is a disease reporting and
					identification mechanism which has a limited impact on detection and
					de-risking fish disease.
				</p>
				<h4 className='text-lg font-medium mb-2'>
					A Broader Perspective: Technological Innovations in Aquaculture
					Disease Management
				</h4>
				<ul className='list-disc list-inside mb-4'>
					<li>
						<strong>Image Analysis and Artificial Intelligence (AI):</strong>
						Image detection and AI employed convolutional neural networks (CNNs)
						to accurately classify different types of fish diseases based on
						image analysis of fish skin lesions.{' '}
						<a
							href='https://ijnaa.semnan.ac.ir/article_5839.html'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							(Hasan et al., 2022)
						</a>
					</li>

					<li>
						<b>Molecular Diagnostics:</b> A rapid PCR-based test for detecting
						viral hemorrhagic septicemia (VHS) in salmon has been developed and
						validated, enabling faster and more accurate diagnosis. A study by
						<a
							href='https://iopscience.iop.org/article/10.1088/1674-4926/44/2/023103'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							Liu et al. (2023)
						</a>{' '}
						employed metagenomics and NGS to characterise the microbial
						communities associated with healthy and diseased fish, identifying
						potential disease biomarkers.
					</li>
					<li>
						<b>Spectroscopy::</b> Near-infrared spectroscopy (NIR) has been used
						to differentiate between healthy and diseased shrimp based on
						changes in tissue composition. According to a study by{' '}
						<a
							href='https://www.frontiersin.org/journals/microbiology/articles/10.3389/fmicb.2021.683580/full'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							Wang et al. (2021),
						</a>{' '}
						Raman spectroscopy has shown promise in detecting bacterial
						infections in fish by identifying specific spectral signatures
						associated with pathogenic bacteria.
					</li>
					<li>
						<b>Other Emerging Technologies: </b>
						Drone-based surveillance has been employed in aquaculture farms to
						monitor fish populations, detect abnormal behaviour, and identify
						potential disease outbreaks. Autonomous underwater vehicles (AUVs)
						equipped with sensors can collect water quality data, detect fish
						diseases, and even administer treatments, improving efficiency and
						reducing labour costs.{' '}
						<a
							href='https://onlinelibrary.wiley.com/doi/10.1111/raq.12870'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							(Bohara et al., 2023)
						</a>{' '}
					</li>
					<li>
						<b>Precision Aquaculture:</b> According to a review study by{' '}
						<a
							href='https://onlinelibrary.wiley.com/doi/10.1111/jwas.13051'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							Islam et al. (2024)
						</a>{' '}
						Leveraging data analytics and IoT sensors, farmers can monitor fish
						health parameters in real-time, enabling early detection of disease
						outbreaks.
					</li>
					<li>
						<b>Probiotics and Immunostimulants: </b>
						Research into beneficial bacteria and immune-boosting compounds is
						gaining traction, offering natural alternatives to antibiotics.{' '}
						<a
							href='https://carijournals.org/journals/index.php/AHJ/article/view/1779'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							(Omolloh, 2024)
						</a>{' '}
					</li>
					<li>
						<b>Advanced Diagnostics:</b> Rapid and accurate disease diagnosis
						tools are being developed to enable swift and targeted treatment
						strategies.
					</li>
					<li>
						<b>Vaccine Development:</b> The aquaculture industry is investing in
						research to develop effective vaccines against major fish diseases.{' '}
						<a
							href='https://www.mdpi.com/2076-393X/12/7/732'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							(Tammas et al., 2024)
						</a>{' '}
					</li>
					<li>
						<b>Aquaculture Biosecurity:</b> Implementing stringent biosecurity
						measures, including disinfection protocols and quarantine
						procedures, is crucial for preventing disease outbreaks.{' '}
						<a
							href='https://www.tandfonline.com/doi/full/10.1080/10454438.2015.1084164'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							(Palić et al., 2015)
						</a>{' '}
					</li>
				</ul>
			</section>

			<section className='mb-8'>
				<h3 className='text-xl font-semibold mb-2'>
					Additional Considerations
				</h3>
				<ul className='list-disc list-inside mb-4'>
					<li>
						<strong>Integration of Technologies:</strong>
						Combining multiple technologies can enhance disease detection
						accuracy and provide a more comprehensive understanding of fish
						health. For example, image analysis can be used to identify
						potential disease outbreaks, followed by molecular diagnostics to
						confirm the specific pathogen.
					</li>
					<li>
						<strong>Data Management and Analysis:</strong> Effective data
						management and analysis are crucial for extracting valuable insights
						from the collected data. Advanced analytics techniques can help
						identify patterns and trends associated with disease outbreaks.
					</li>
					<li>
						<strong>Cost-Effectiveness:</strong> The implementation of advanced
						technologies should be economically viable for aquaculture farmers.
						There is a need for developing affordable and accessible solutions.
					</li>
				</ul>
			</section>

			<section className='mb-8'>
				<div className='flex flex-col p-4 border border-4 border-[#00208C] rounded-lg bg-slate-50'>
					<div className='flex items-center justify-center py-2'>
						<img
							src={AQD}
							alt='A healthy pond'
							className='w-[200px] rounded-lg'
						/>
					</div>
					<h3 className='text-3xl font-semibold mb-2'>
						AquaNurch Digital Twin®: A Resilient Net to De-risk Fish Farmers
						from Fish Diseases
					</h3>
					<p className='mb-4'>
						NatureDots' <b>AquaNurch Digital Twin® </b>system plays a pivotal
						role in disease prevention by providing real-time water quality
						monitoring and early warning systems. By identifying potential
						disease hotspots, farmers can take proactive measures to protect
						their fish stocks.
					</p>
					<p className='mb-4 italic ml-20 text-xs'>
						About NatureDots: NatureDots combines ‘Nature Intelligence x
						Artificial Intelligence’ to ensure Water quality and quantity
						insecurity is real, 2 billion people live in countries experiencing
						high water stress. AquaNurch Digital Twin® uses AI to process
						millions of water and environmental data for detecting risk and
						empower users to mitigate risks - ensuring healthy fisheries,
						pollution free drinking water supply and health ecosystem for
						nature. Visit{' '}
						<a
							href='https://www.naturedots.com'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.naturedots.com
						</a>{' '}
						to know more
					</p>
					<p className='mb-4'>
						<b>AquaNurch Digital Twin®</b> A combination of hardware and
						software which is being co-designed with fish-farmers to ensure
						human-centred design core, its key focus is on inland freshwater
						fish-farmers who face the direct burnt of water-security and fish
						disease risks. The system intuitively uses AI and science based
						approaches to detect risk timely and forecast potential risks.
					</p>
					<div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
						<div className='flex flex-col space-y-3 items-center h-full'>
							<div className='flex justify-center my-4 h-64'>
								<img
									src={IMG9}
									alt='A healthy pond'
									className='w-full h-full object-cover rounded-lg shadow-md'
								/>
							</div>
							<p className='text-gray-400 text-sm pb-2 text-center'>
								A healthy pond thriving with balanced ecosystems using AquaNurch
							</p>
						</div>

						<div className='flex flex-col space-y-3 items-center h-full'>
							<div className='flex justify-center my-4 h-64'>
								<img
									src={IMG10}
									alt='fish farmers'
									className='w-full h-full object-cover rounded-lg shadow-md'
								/>
							</div>
							<p className='text-gray-400 text-sm pb-2 text-center'>
								Engaging with fish farmers, understanding their challenges and
								needs
							</p>
						</div>

						<div className='flex flex-col space-y-3 items-center h-full'>
							<div className='flex justify-center my-4 h-64'>
								<img
									src={IMG11}
									alt='AquaNurch hardware'
									className='w-full h-full object-cover rounded-lg shadow-md'
								/>
							</div>
							<p className='text-gray-400 text-sm pb-2 text-center'>
								Showcasing AquaNurch's innovative hardware for smarter,
								data-driven aquaculture
							</p>
						</div>

						<div className='flex flex-col space-y-3 items-center h-full'>
							<div className='flex justify-center my-4 h-64'>
								<img
									src={IMG12}
									alt='training farmers'
									className='w-full h-full object-cover rounded-lg shadow-md'
								/>
							</div>
							<p className='text-gray-400 text-sm pb-2 text-center'>
								Empowering fish farmers through hands-on training to harness
								technology via the AquaNurch mobile app
							</p>
						</div>
					</div>

					<p className='mb-4'>
						<b>AquaNurch acts as a risk detection and early warning system</b>.
						Its AI powered camera for disease detection and end-to-end solution
						to reduce mortality and achieve optimum growth.
					</p>
					<div>
						<div className='flex flex-col space-y-3 items-center'>
							<img
								src={IMG13}
								alt='dead fish'
								className='rounded-lg shadow-md sm:w-[50%] sm:h-[50%]'
							/>
							<p className='text-gray-400 text-sm pb-2'>
								Women fish seller at Raipur, Chhattisgarh
							</p>
						</div>
					</div>
					<p className='mb-4'>
						<b>AquaNurch Fish Doctor</b> provides online diagnosis of fish
						disease and its AI-model identity and suggests mitigating and
						preventive measures on a location and fish type specific manner.
						<b>AquaNurch Fish Doctor</b> reduces mortality below 5% and detects
						major fish diseases.
					</p>
				</div>
			</section>

			<section className='mb-8'>
				<h3 className='text-xl font-semibold mb-2'>A Collaborative Approach</h3>
				<div className='flex sm:flex-row flex-col sm:space-x-8'>
					<ul className='list-disc list-inside mb-4 sm:w-[50%]'>
						<p className='mb-4'>
							Addressing the complex issue of fish disease requires a
							collaborative effort from governments, research institutions,
							aquaculture farmers, and technology providers. By working
							together, we can build a more resilient and sustainable
							aquaculture industry.
						</p>
						<p className='mb-4'>
							By incorporating these cutting-edge technologies and fostering
							collaboration, the aquaculture industry can overcome the
							challenges posed by fish diseases and ensure a sustainable future
							for this vital sector.
						</p>
					</ul>

					<div className='flex flex-col space-y-3 items-center'>
						<div className='flex justify-center'>
							<img
								src={IMG14}
								alt='dead fish'
								className='w-fit h-fit rounded-lg shadow-md'
							/>
						</div>
						<p className='text-gray-400 text-sm pb-2'>
							A 1-hectare fish pond for Indian Major Carps under AquaNurch in
							Durg district, Chhattisgarh.
						</p>
					</div>
				</div>
			</section>

			<section class='text-xs'>
				<h3 className='text-xl font-semibold mb-2'>References</h3>
				<ol class='list-decimal ml-4 flrx flex-col space-y-2'>
					<li>
						Aich, N., Paul, A., Choudhury, T. G., & Saha, H. (2022). Tilapia
						Lake Virus (TiLV) disease: Current status of understanding.{' '}
						<span className='italic'>Aquaculture and Fisheries,</span> 7(1),
						7–17.
						<a
							href='https://doi.org/10.1016/j.aaf.2021.04.007'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.1016/j.aaf.2021.04.007
						</a>
					</li>
					<li>
						Annual Report 2022-23. (2024).{' '}
						<span className='italic'>
							In The Marine Products Export Development Authority
						</span>
						.
						<a
							href='https://mpeda.gov.in/wp-content/uploads/2020/12/mpeda-annual%20report%202022-2023-2.pdf'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://mpeda.gov.in/annual-report-2022-23.pdf
						</a>
					</li>
					<li>
						Argulus mongolianus. (2021). In{' '}
						<span className='italic'>Institute of Fisheries Management.</span>
						<a
							href='https://ifm.org.uk/wp-content/uploads/2021/08/Argulus-mongolianus-1.pdf'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://ifm.org.uk/argulus-mongolianus.pdf
						</a>
					</li>
					<li>
						Badhusha, A., Mithra, S., Ghani, T. S., Rajkumar, V., Abdul Majeed,
						S., Suryakodi, S., Haridass, L., Haridass, D., Sahoo, P. K.,
						Mohanty, J., Paul, A., Mohanty, S., Pillai, D., Rejish Kumar, V. J.,
						& Hameed, S. (2023). Detection of Tilapia parvovirus in farm reared
						tilapia in India and its isolation using fish cell lines.{' '}
						<span className='italic'> Research Square.</span>
						<a
							href='https://doi.org/10.21203/rs.3.rs-3373088/v1'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.21203/rs.3.rs-3373088/v1
						</a>
					</li>
					<li>
						Behera, B. K., Parida, S. N., Kumar, V., Swain, H. S., Parida, P.
						K., Bisai, K., Dhar, S., & Das, B. K. (2023).{' '}
						<span className='italic'>Aeromonas veronii </span> Is a Lethal
						Pathogen Isolated from Gut of Infected{' '}
						<span className='italic'>Labeo rohita</span>: Molecular Insight to
						Understand the Bacterial Virulence and Its Induced Host Immunity.
						<span className='italic'>Pathogens, 12(4), 598.</span>
						<a
							href='https://doi.org/10.3390/pathogens12040598'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.3390/pathogens12040598
						</a>
					</li>
					<li>
						Bohara, K., Joshi, P., Acharya, K. P., & Ramena, G. (2023). Emerging
						technologies revolutionising disease diagnosis and monitoring in
						aquatic animal health.{' '}
						<span className='italic'>
							Reviews in Aquaculture, 16(2), 836–854.
						</span>
						<a
							href='https://doi.org/10.1111/raq.12870'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.1111/raq.12870
						</a>
					</li>
					<li>
						Das, B., Haridas, D., Jacob, N., Ramakrishnan, A., Ph, A., Vj, R., &
						Pillai, D. (2021). Outbreaks of epizootic ulcerative syndrome in
						Kerala, India following episodes of flooding.
						<span className='italic'>
							{' '}
							Diseases of Aquatic Organisms, 143, 189–193.
						</span>
						<a
							href='https://doi.org/10.3354/dao03567'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.3354/dao03567
						</a>
					</li>
					<li>
						<span className='italic'>
							Handbook of Fisheries Statistics 2023. (2024).
						</span>
						<a
							href='https://dof.gov.in/sites/default/files/2024-03/handbook_sp.pdf'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://dof.gov.in/handbook_sp.pdf
						</a>
					</li>
					<li>
						Hasan, N., Ibrahim, S., & Azlan, A. A. (2022). Fish diseases
						detection using convolutional neural network (CNN).
						<span className='italic'>
							{' '}
							International Journal of Nonlinear Analysis and Applications,
							13(1), 1977–1984.
						</span>
						<a
							href='https://ijnaa.semnan.ac.ir/article_5839.html'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://ijnaa.semnan.ac.ir/article_5839.html
						</a>
					</li>

					<li>
						Islam, S. I., Ahammad, F., & Mohammed, H. (2024). Cutting‐edge
						technologies for detecting and controlling fish diseases: Current
						status, outlook, and challenges.
						<span className='italic'>
							{' '}
							Journal of the World Aquaculture Society, 55(2).
						</span>
						<a
							href='https://onlinelibrary.wiley.com/doi/10.1111/jwas.13051'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://onlinelibrary.wiley.com/doi/10.1111/jwas.13051
						</a>
					</li>

					<li>
						Liu, X., Qiu, S., Fang, H., Mei, L., Jing, H., Feng, C., Wu, S., &
						Lin, X. (2023). A brief review of novel nucleic acid test biosensors
						and their application prospects for salmonids viral diseases
						detection. Journal of Semiconductors, 44(2), 023103.
						<span className='italic'>
							{' '}
							Journal of Semiconductors, 44(2), 023103
						</span>
						<a
							href='https://iopscience.iop.org/article/10.1088/1674-4926/44/2/023103'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://iopscience.iop.org/article/10.1088/1674-4926/44/2/023103
						</a>
					</li>

					<li>
						Mohan, C. V., Delamare-Deboutteville, J., Beveridge, M. C. M., &
						Marwaha, N. (2021).{' '}
						<span className='italic'>
							{' '}
							Reducing disease risks in fish through better detection,
							management and prevention{' '}
						</span>{' '}
						(Program Brief: FISH-2021-07).
						<a
							href='https://digitalarchive.worldfishcenter.org/bitstream/handle/20.500.12348/4833/a4fd37f3aba7ab43f32fabc627508032.pdf'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://worldfishcenter.org/FISH-2021-07.pdf
						</a>
					</li>

					<li>
						Omolloh, B. (2024). Antibiotic Alternatives for Controlling
						Bacterial Infections in Aquaculture.
						<span className='italic'>
							{' '}
							Animal Health Journal, 5(1), 39–51.{' '}
						</span>{' '}
						<a
							href='https://carijournals.org/journals/index.php/AHJ/article/view/1779'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://carijournals.org/journals/index.php/AHJ/article/view/1779
						</a>
					</li>

					<li>
						Palić, D., Scarfe, A. D., & Walster, C. I. (2015). A Standardized
						Approach for Meeting National and International Aquaculture
						Biosecurity Requirements for Preventing, Controlling, and
						Eradicating Infectious Diseases.
						<span className='italic'>
							{' '}
							Journal of Applied Aquaculture, 27(3), 185–219.{' '}
						</span>{' '}
						<a
							href='https://www.tandfonline.com/doi/full/10.1080/10454438.2015.1084164'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.tandfonline.com/doi/full/10.1080/10454438.2015.1084164
						</a>
					</li>

					<li>
						Patil, P. K., Geetha, R., Ravisankar, T., Avunje, S., Solanki, H.
						G., Abraham, T. J., Vinoth, S. P., Jithendran, K. P., Alavandi, S.
						V., & Vijayan, K. K. (2021). Economic loss due to diseases in Indian
						shrimp farming with special reference to Enterocytozoon hepatopenaei
						(EHP) and white spot syndrome virus (WSSV).{' '}
						<span className='italic'> Aquaculture, 533, 736231.</span>
						<a
							href='https://doi.org/10.1016/j.aquaculture.2020.736231'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.1016/j.aquaculture.2020.736231
						</a>
					</li>
					<li>
						Rajendran, K. V., Sood, N., Rao, B. M., Valsalam, A., Bedekar, M.
						K., Jeena, K., Pradhan, P. K., Paria, A., Swaminathan, T. R., Verma,
						D. K., & Sood, N. K. (2023). Widespread occurrence of Tilapia
						parvovirus in farmed Nile tilapia{' '}
						<span className='italic'> Oreochromis niloticus </span> from India.
						<span className='italic'>Journal of Fish Diseases.</span>
						<a
							href='https://doi.org/10.1111/jfd.13871'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.1111/jfd.13871
						</a>
					</li>
					<li>
						Sahoo, P. K., Paul, A., Sahoo, M. K., Pattanayak, S., Rajesh Kumar,
						P., & Das, B. K. (2020). Incidences of Infectious Diseases in
						Freshwater Aquaculture Farms of Eastern India: A Passive
						Surveillance based Study from 2014-2018.{' '}
						<span className='italic'>
							Journal of Aquaculture Research & Development, 11(1).
						</span>
						<a
							href='https://doi.org/10.35248/2155-9546.20.11.579'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.35248/2155-9546.20.11.579
						</a>
					</li>
					<li>
						Stentiford, G. (2017, February 2).{' '}
						<span className='italic'>
							Solving the $6 billion per year global aquaculture disease
							problem.
						</span>{' '}
						Centre for Environment, Fisheries and Aquaculture Science.
						<a
							href='https://marinescience.blog.gov.uk/2017/02/02/solving-the-6-billion-per-year-global-aquaculture-disease-problem/'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://marinescience.blog.gov.uk/aquaculture-disease-problem/
						</a>
					</li>

					<li>
						Tammas, I., Bitchava, K., & Gelasakis, A. I. (2024). Transforming
						Aquaculture through Vaccination: A Review on Recent Developments and
						Milestones.
						<span className='italic'>Vaccines, 12(7), 732.</span>{' '}
						<a
							href='https://www.mdpi.com/2076-393X/12/7/732'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.mdpi.com/2076-393X/12/7/732
						</a>
					</li>

					<li>
						The State of World Fisheries and Aquaculture 2024. (2024). In{' '}
						<span className='italic'>FAO eBooks.</span>
						<a
							href='https://doi.org/10.4060/cd0683en'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.4060/cd0683en
						</a>
					</li>

					<li>
						Wang, L., Liu, W., Tang, J., Wang, J., Liu, Q., Wen, P., Wang, M.,
						Pan, Y., Gu, B., & Zhang, X. (2021). Applications of Raman
						Spectroscopy in Bacterial Infections: Principles, Advantages, and
						Shortcomings.
						<span className='italic'>Frontiers in Microbiology, 12.</span>
						<a
							href='https://www.frontiersin.org/journals/microbiology/articles/10.3389/fmicb.2021.683580/full'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.frontiersin.org/journals/microbiology/articles/10.3389/fmicb.2021.683580/full
						</a>
					</li>

					<li>
						Workeale, A., & Melkamu, S. (2021). Gross and Histopathological
						Lesion Induced on the Gills Ofbarbus Fish by Monogenea Trematods
						Habiteting in Western Part of Lake Tana, Amhara Region, Ethiopia.
						<span className='italic'>Research Square (Research Square).</span>
						<a
							href='https://doi.org/10.21203/rs.3.rs-832497/v1'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://doi.org/10.21203/rs.3.rs-832497/v1
						</a>
					</li>
				</ol>
			</section>

			<section className='mb-8 mt-8'>
				<p className='font-bold italic text-[#0000ff]'>
					Discover the power of AquaNurch Digital Twin and gain access to
					exclusive newsletters, reports, and tech demos. Fill out this form to
					get started.
				</p>
				<div className='w-full flex items-center justify-center'>
					<div className='flex max-w-[380px] mt-5'>
						<button
							className='bg-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-800 transition-colors'
							onClick={() =>
								navigate(`/product`, {
									state: { params1: 'subscribed' },
								})
							}
						>
							Subscribe
						</button>
					</div>
				</div>
			</section>
		</div>
	);
}
